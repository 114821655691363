import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

import { ApiService } from '../api/api.service';
import { UsersService } from '../users/users.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  urlApi: string;
  path: string = '';

  constructor(private api: ApiService) { 
    this.urlApi = environment.api_url;
  }

  isValidToken(): Observable<any> {
    return this.api.get(this.urlApi, 'isClient');
  }

  login(params: Params): Observable<any> {
    return this.api.post(this.urlApi, 'login', params, {responseType: 'text'});
  }
  
  forgot(params: Params): Observable<any> {
    return this.api.post(this.urlApi, 'forgotPassword', params);
  }

  verify(params: Params): Observable<any> {
    return this.api.post(this.urlApi, 'remember', params, {responseType: 'text'});
  }

  logout(){
    localStorage.clear();
    /*
    localStorage.removeItem("jwt");
    localStorage.removeItem("userAlias");
    localStorage.removeItem("userName");
    */
  }

  getJWT(){
    return localStorage.getItem("jwt");
  }

  saveJWT(token: string){ 
    localStorage.setItem("jwt", token);
  }

}
